/* Animation de la navbar cachée */
.header-hidden {
  transform: translateY(-100%);
}

.header-visible {
  transform: translateY(0);
}

.header {
  transition: transform 0.3s ease-in-out;
}
